export const getLanguage = () => {
  const defaultLang = 'en';

  // get language/locale from browser, fallback to default language
  let language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    defaultLang;
  // use lang without region locale

  language = language.split(/[-_]/)[0];

  return language;
};
