import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

class LanguageSwitcher extends React.Component {
  render() {
    return (
      <div className="language-switcher">
        <ul className="d-flex flex-row justify-content-center px-5">
          <li className="d-block">
            <Button
              color="link"
              onClick={() => this.props.handleLangChange('de')}
            >
              DE
            </Button>
          </li>
          <li className="d-block">
            <Button
              color="link"
              onClick={() => this.props.handleLangChange('en')}
            >
              EN
            </Button>
          </li>
        </ul>
      </div>
    );
  }
}

LanguageSwitcher.propTypes = {
  handleLangChange: PropTypes.func.isRequired
};

export default LanguageSwitcher;
