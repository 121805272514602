import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services';

const LogOut = ({ logOut }) => {
  const navigate = useNavigate();

  useEffect(() => {
    logOut();
    navigate('/login');
  }, [logOut, navigate]);

  return null;
};

LogOut.propTypes = {
  logOut: PropTypes.func.isRequired,
};

export default connect(null, { logOut: apiService.logOut })(LogOut);
