import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Autosuggest from 'react-autosuggest';
import { Close, Search } from 'grommet-icons';
import { FormGroup, Input, InputGroup } from 'reactstrap';
import {
  getAutocomplete,
  getPatientScope,
  clearSuggestions,
  setPatientScope
} from '../../actions/patientScopeSwitcher';

class PatientScopeSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestionOpen: false,
      patientScope: ''
    };

    this.timeout = undefined;
  }

  componentDidMount() {
    this.props.getPatientScope();
  }

  componentDidUpdate() {
    const { patientScope, location } = this.props;
    // Check if patient id is set in URL, otherwise leave as is
    const patient = new URLSearchParams(location.search).get('patient') || patientScope.patient_id;

    if (patient !== patientScope.patient_id && !patientScope.isSetting) {
      this.props.setPatientScope(patient);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  onChange = event => {
    this.setState({
      suggestionOpen: true,
      patientScope: event.target.value
    });

    if (event.target.value === '') {
      this.props.setPatientScope('');
    }
  };

  onBlur = () =>
      this.setState({
        suggestionOpen: false
      });

  onMouseOut = () => {
    this.timeout = setTimeout(this.onBlur, 1000);
  };

  onSelect = (event, { suggestion }) => {
    // get predefined search value from url
    const searchParam =
        new URLSearchParams(this.props.location.search).get('searchParam') || '';
    const searchLabel =
        new URLSearchParams(this.props.location.search).get('searchLabel') || '';
    const searchType =
        new URLSearchParams(this.props.location.search).get('searchType') || '';

    let params = {};

    if (searchParam && searchLabel && searchType) {
      params.searchParam = searchParam;
      params.searchLabel = searchLabel;
      params.searchType = searchType;
    }

    this.props.setPatientScope(suggestion.value);
    this.setState({
      suggestionOpen: false,
      patientScope: ''
    });

    let location = window.location.href;
    window.location.href =
        location +
        '?' +
        new URLSearchParams({
          patient: suggestion.value,
          ...params
        }).toString();
  };

  loadAutocomplete = value => this.props.getAutocomplete(value);

  getSuggestionValue = suggestion => {
    return suggestion.label;
  };

  renderSuggestion = suggestion => {
    return <div>{suggestion.label}</div>;
  };

  renderInputComponent = inputProps => (
      <InputGroup>
        <Search className="searchIcon" />
        <Input name="patientScope" size="sm" {...inputProps} />
        <Close className="clearIcon" onClick={this.clearSearch} />
      </InputGroup>
  );

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const { suggestionOpen } = this.state;
    const { isFetching, autocompleteData } = this.props;

    if (
        !isFetching &&
        suggestionOpen &&
        query.length > 2 &&
        autocompleteData.length <= 0
    ) {
      return (
          <div {...containerProps}>
            <div className="react-autosuggest__suggestion-list--empty">
              <FormattedMessage id="search.autocomplete_no_results" />
            </div>
          </div>
      );
    }
    return <div {...containerProps}>{children}</div>;
  };

  clearSearch = () => {
    // get predefined search value from url

    const urlParams = new URLSearchParams(window.location.search);
    const searchParam = urlParams.get('searchParam') || '';
    const searchLabel = urlParams.get('searchLabel') || '';
    const searchType = urlParams.get('searchType') || '';

    let params = {};

    if (searchParam && searchLabel && searchType) {
      params.searchParam = searchParam;
      params.searchLabel = searchLabel;
      params.searchType = searchType;
    }

    window.location.href =
        window.location.pathname +
        '?' +
        new URLSearchParams(params).toString();

    this.props.setPatientScope('');
    this.setState({ patientScope: '' });
  };

  clearSuggestions = () => {
    this.props.clearSuggestions();
  };

  render() {
    const { intl, autocompleteData } = this.props;

    const inputProps = {
      placeholder: intl.formatMessage({ id: 'patientScopeSwitcher.inputText' }),
      value: this.state.patientScope,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onMouseOut: this.onMouseOut,
      autoFocus: false
    };

    return (
        <FormGroup className="patient-scope">
          <Autosuggest
              suggestions={autocompleteData}
              onSuggestionsFetchRequested={this.loadAutocomplete}
              getSuggestionValue={this.getSuggestionValue}
              inputProps={inputProps}
              renderSuggestion={this.renderSuggestion}
              renderInputComponent={this.renderInputComponent}
              renderSuggestionsContainer={this.renderSuggestionsContainer}
              highlightFirstSuggestion={true}
              onSuggestionSelected={this.onSelect}
              focusInputOnSuggestionClick={false}
              onSuggestionsClearRequested={this.clearSuggestions}
          />
        </FormGroup>
    );
  }
}

PatientScopeSwitcher.propTypes = {
  intl: PropTypes.object.isRequired,
  autocompleteData: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  getAutocomplete: PropTypes.func,
  patientScope: PropTypes.object,
  setPatientScope: PropTypes.func,
  getPatientScope: PropTypes.func,
  clearSuggestions: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    // @todo: is there an update mechanism for existing states?
    autocompleteData: state.patientScopeSwitcher
        ? state.patientScopeSwitcher.autocompleteData
        : [],
    isFetching: state.patientScopeSwitcher
        ? state.patientScopeSwitcher.isFetchingAutocomplete
        : false,
    patientScope: state.patientScopeSwitcher
        ? state.patientScopeSwitcher.patientScope
        : {}
  };
};

export default connect(mapStateToProps, {
  getAutocomplete,
  getPatientScope,
  setPatientScope,
  clearSuggestions
})(injectIntl(PatientScopeSwitcher));
