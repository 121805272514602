import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Close, Down, StatusGood } from 'grommet-icons';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Col,
  Row
} from 'reactstrap';
import Recommendation from '../../screens/Recommendation';

class SearchResultItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  recommendationStats = {
    action_required: (
        <Alert className="status-icon red py-0 my-0 py-md-1 my-md-1" />
    ),
    no_action_required: (
        <StatusGood className="status-icon green py-0 my-0 py-md-1 my-md-1" />
    ),
    no_gene_drug_interaction_known: (
        <StatusGood className="status-icon green py-0 my-0 py-md-1 my-md-1" />
    )
  };

  renderContent = data => {
    const { isOpen } = this.state;
    return (
        <Card className="search-result-container">
          <CardHeader className="search-result-header">
          <span className="search-result-header__label">
            <FormattedMessage id="search.gene" />
          </span>
            {data.gene_name}
          </CardHeader>
          <CardBody>
            <Container>
              <Row>
                <Col className="search-result-content-container">
                  <Row>
                    <Col
                        xs="12"
                        md="4"
                        className="search-result-content__box text-center d-flex flex-column justify-content-center"
                    >
                      <div>
                        <div className="search-result-content__label">
                          <FormattedMessage id="search.active_ingredient_name" />
                        </div>
                        <strong>{data.active_ingredient_name}</strong>
                      </div>
                    </Col>
                    <Col
                        md="1"
                        className="d-none d-md-flex justify-content-between align-items-center p-0"
                    >
                      <div className="linkage-line" />
                    </Col>
                    <Col
                        xs="12"
                        md="2"
                        className="search-result__icon-container d-flex flex-column align-items-center justify-content-center p-0"
                    >
                      {this.recommendationStats[data.recommendation_status] || ''}
                    </Col>
                    <Col
                        md="1"
                        className="d-none d-md-flex justify-content-between align-items-center p-0"
                    >
                      <div className="linkage-line" />
                    </Col>
                    <Col
                        xs="12"
                        md="4"
                        className="search-result-content__box text-center d-flex flex-column justify-content-center"
                    >
                      <div className="search-result-content__label">
                        <FormattedMessage id="searchResultItem.myResult" />
                      </div>
                      <strong>{data.phenotype_name}</strong>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </CardBody>
          <CardFooter>
            {isOpen ? (
                <Button block className="view-less-btn" onClick={this.toggle}>
                  <Close className="view-less-icon" />
                  <FormattedMessage id="searchResultItem.viewLess" />
                </Button>
            ) : (
                <Button block className="view-more-btn" onClick={this.toggle}>
                  <FormattedMessage id="searchResultItem.viewMore" />
                  <div>
                    <Down className="view-more-icon" />
                  </div>
                </Button>
            )}
            {isOpen && <Recommendation geneName={data.gene_name} />}
          </CardFooter>
        </Card>
    );
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { data } = this.props;

    return <div className="search-result-item">{this.renderContent(data)}</div>;
  }
}

SearchResultItem.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  data: PropTypes.object.isRequired,
  searchItem: PropTypes.object
};

export default SearchResultItem;
