import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { LinkNext } from 'grommet-icons';

const LinkedListItem = ({ to, label, ...rest }) => (
  <ListGroupItem {...rest}>
    <NavLink to={to}>
      <LinkNext />
      <span className="label">{label}</span>
    </NavLink>
  </ListGroupItem>
);

LinkedListItem.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string
};

const LinkedList = ({ listItems, ...rest }) => (
  <ListGroup className="linked-list" {...rest}>
    {listItems.map(item => {
      return (
        <LinkedListItem
          key={item.id || item.label}
          to={item.link || ''}
          label={item.label}
        />
      );
    })}
  </ListGroup>
);

LinkedList.propTypes = {
  listItems: PropTypes.array
};

export default LinkedList;
