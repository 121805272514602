export default {
  en: {
    app: {
      getAndroidApp: 'Get pharma.sensor for Android.',
      getiOSApp: 'Get pharma.sensor for iOS.',
      loading: 'Loading...'
    },
    logIn: {
      welcome: 'Welcome',
      passwordReset: 'Forgot password?',
      loginError:
        'Wrong username or password. Please try again or get a new Password.',
      cookieHint:
        'This website uses cookies to support a comfortable user experience. For more information see our privacy policy.',
      cookieHintButton: 'I understand',
      changePasswordAfterFirstLogin:
        'Please change your initial passwort, before proceeding with the application.',
      logOut: 'Log out',
      or: 'or'
    },
    loginForm: {
      username: 'Username',
      password: 'Password',
      showPassword: 'Show password',
      loginButton: 'Log in'
    },
    navbar: {
      logout: 'Log out',
      settings: 'Settings',
      check: 'Drug check',
      mydna: 'My DNA analysis',
      report: 'Clinical report',
      drugs: 'Active ingredients',
      genes: 'Genes',
      results: 'Results',
      favorites: 'Favorites',
      help: 'Support',
      more: 'More',
      userInfo: 'logged in as',
      patientInfo: 'selected patient:',
      noSelection: 'none',
      loading: 'Loading...'
    },
    settings: {
      loading: 'Settings changes are being saved...'
    },
    passwordForm: {
      header: 'Change password',
      currentPassword: 'Current password',
      newPassword: 'New password',
      newPasswordConfirm: 'Confirm new password',
      confirmPasswordError:
        'The values entered for the new password must be identical.',
      submitButton: 'Change password',
      changeError: `Your current password is missing or incorrect. It's required to change the password.`,
      success: 'Your password was successfully changed!',
      hint: 'For security reasons we recommend to change your password.',
      showPassword: 'Show password'
    },
    emailForm: {
      reasonHeader: 'Enter email address',
      reason1: 'to restore your password,\nin case you forget it',
      reason2:
        'for notifications in case of updates\ne.g. of recommendations for drug therapy',
      email: 'Enter email address',
      emailConfirm: 'Confirm email address',
      password: 'Enter password',
      currentPassword: 'your password',
      submitButton: 'Save email address',
      error: 'Your email address could not be changed. Please try again.',
      success: 'Email address changed successfully!',
      confirmEmailError: 'The email confirmation is incorrect.',
      skipButton: 'Skip'
    },
    requestPasswordResetForm: {
      header: 'Password reset',
      description:
        'Enter your username and we will send you an e-mail with further instructions.',
      username: 'Enter username',
      usernameConfirmation: 'Confirm username',
      submitButton: 'Submit form',
      cancel: 'Cancel and go back to login',
      error:
        'The entered username is wrong or the user does not have an email address assigned',
      success: 'Further instructions have been sent to your email address',
      confirmationError:
        'The entered values are not identical. Please try again'
    },
    passwordResetForm: {
      header: 'Password reset',
      newPassword: 'Enter New password',
      newPasswordConfirmation: 'Confirm new password',
      submitButton: 'Save password',
      success: 'Your password was reseted successfully',
      confirmationError:
        'The enetered values are not identical, please try again',
      error:
        'There was an error while processing your request, please try again',
      redirect: 'Go to login'
    },
    languageForm: {
      header: 'Preferred language',
      error: 'Language could not be changed. Please try again.',
      de: 'German',
      en: 'English'
    },
    footer: {
      imprint: 'Imprint',
      terms: 'Terms and conditions',
      policy: 'Privacy policy'
    },
    search: {
      intro:
        'search for drugs and active ingredients to get personal recommendations',
      inputText:
        'type in drugs, active ingredients or ‚Pharmazentralnummer‘ (PZN code)',
      most_common_search: 'most common searches for active ingredients:',
      autocomplete_no_results: 'No results found',
      search_param: 'You searched for:',
      search_result_description:
        'Your search delivered results for the following active ingredients:',
      favorite_button_add: 'Add to favorites',
      favorite_button_remove: 'Remove from favorites',
      search_results_empty: `For this active ingredient, there are currently no treatment recommendations based on DNA variants.`,
      fetching: 'Search is being processed, please wait...',
      legend: {
        button: "What's the meaning of the symbols?",
        text: {
          good: 'Your DNA variants do not affect the effect of this substance.',
          alert:
            'Your DNA variants are relevant to the effect of this substance.\n Please refer to the recommendations.'
        },
        close: 'Close'
      },
      sections: {
        recommendation: 'recommendation',
        gene_info: 'gene',
        background_info: 'scientific background',
        references: 'literature'
      },
      no_info: 'This feature will be available soon.',
      clear_search: 'Clear search',
      active_ingredient_name: 'active ingredient',
      drug_name: 'drug',
      gene: 'Gene',
      pzn: 'PZN'
    },
    help: {
      text: `Questions? Contact us:`,
      tel: 'tel',
      fax: 'fax'
    },
    searchResultItem: {
      myResult: 'my DNA-variant',
      viewMore: 'view more',
      viewLess: 'view less'
    },
    tutorial: {
      openModal: 'Tutorial pharmacogenetics',
      title: 'Tutorial',
      closeButton: 'Cancel',
      prev: 'Previous',
      next: 'Next',
      slide1: {
        title: 'Overview: genes, DNA variants, proteins and drug efficacy',
        subTitle: 'Genes',
        list:
          'are DNA sections contain the blueprint for making proteins'
      },
      slide2: {
        title: 'How a drug travels through our body',
        list:
          'certain proteins are important for drugs to be effective'
      },
      slide3: {
        title: 'DNA variants',
        list:
          'individual DNA variants in genes influence the function of these proteins'
      },
      slide4: {
        title: 'Effects of DNA variants on drugs',
        list:
          'therefore, the efficacy of drugs may be different for every person\nthey may be toxic, too strong, or may not work at all'
      }
    },
    report: {
      personalizeButton: 'Create report',
      reportInfo: `
      <p>Please fill the opening form with your personal information before printing. By doing so a doctor can use the available recommendations for a clinical decision.</p>
      <p>
      The report is targeted exclusively to the person, whose analysis results are shown in this user account.
      </p>`,
      reportIsGenerating: `Your report is being generated and will be available shortly. 
      Please note that the PDF will be ready for download for 30 seconds. 
      After that the document and your personal data will be disposed.`,
      noReportFound: '<p>There are no reports available</p>',
      noPersonalizationFields:
        'An error has occured. Please contact the customer support.',
      printJobStatusPending: 'Report is being created...',
      printJobStatusReady: 'Report created successfully.',
      printJobStatusError:
        'Error while creating report. Please try again later.',
      printJobStatusExpired: `Timeout during order processing. For protection of data privacy, 
        personalized reports are deleted after 30 seconds. Please, generate a new report.`,
      sendButton: 'Send',
      downloadButton: 'Download here'
    },
    favorites: {
      loadingMessage: 'Loading your list of favorites',
      empty: "You didn't save any favorite yet",
      editButton: 'Edit',
      finishButton: 'Finish editing',
      drugs: 'Active ingredients / medicaments',
      genes: 'Genes',
      actions: {
        remove: {
          label: 'Remove',
          errorMessage: 'Favorite could not be removed, try again later.'
        }
      }
    },
    recommendation: {
      suggestionText:
        'based on your search, the following information for the active ingredient ‚<span class="emphasized">{activeIngredient}</span>‘ were found',
      notice:
        'This report does not replace a decision made in cooperation with a physician or genetic counselor.\n To avoid serious health risks, change of medicinal therapy should only be done under medical supervision.'
    },
    myDna: {
      noContent: 'No content available.',
      descriptions: {
        genes:
          'Genes in which personal DNA variants were found and their role in drug therapies.',
        drugs: 'Active ingredients with personal dosis recommendations.',
        results: 'All results from my laboratory analysis.'
      },
      loadingMessage: 'Data is loading'
    },
    information: {
      loadingMessage: 'Data is loading'
    },
    feedback: {
      openModalButton: 'Send us feedback',
      title: 'Feedback about the app',
      rating: {
        awesome: 'Awesome!',
        good: 'Good',
        meh: 'Meh!',
        bad: 'Bad',
        horrible: 'Horrible!'
      },
      rateThisPage: 'Rate this page',
      whatDoYouLike: 'What do you like?',
      whatNeedsToBeImproved: 'What needs to be improved?',
      submitButton: 'Submit',
      cancelButton: 'Close',
      statusError: {
        requiredFields:
          'The form could not be sent because some required fields are missing.',
        requestError: 'An error occurred! Please try again later.'
      },
      statusSuccess: 'Thanks for your Feedback!',
      requiredField: 'Required field'
    },
    patientScopeSwitcher: {
      inputText: 'Patienten ID'
    }
  },
  de: {
    app: {
      getAndroidApp: 'Hol dir pharma.sensor für Android.',
      getiOSApp: 'Hol dir pharma.sensor für iOS.',
      loading: 'Inhalte werden geladen...'
    },
    logIn: {
      welcome: 'Willkommen',
      passwordReset: 'Passwort vergessen?',
      loginError:
        'Nutzername oder Passwort falsch. Bitte versuche es noch einmal oder fordere ein neues Passwort an.',
      cookieHint:
        'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.',
      cookieHintButton: 'Ich habe verstanden',
      changePasswordAfterFirstLogin:
        'Bitte ändere dein ursprüngliches Passwort, bevor du mit der Anwendung fortfährst.',
      logOut: 'Ausloggen',
      or: 'oder'
    },
    loginForm: {
      username: 'Benutzername',
      password: 'Passwort',
      loginButton: 'Anmelden',
      showPassword: 'Passwort einblenden'
    },
    navbar: {
      logout: 'Abmelden',
      settings: 'Einstellungen',
      check: 'Medikamenten-Check',
      mydna: 'Meine DNA-Analysen',
      report: 'Befundbericht',
      drugs: 'Wirkstoffe',
      genes: 'Gene',
      results: 'Ergebnisse',
      favorites: 'Favoriten',
      help: 'Support',
      more: 'Mehr',
      userInfo: 'angemeldet als',
      patientInfo: 'ausgewählter Patient:',
      noSelection: 'keine/r',
      loading: 'Lädt...'
    },
    settings: {
      loading: 'Einstellungen werden gespeichert...'
    },
    passwordForm: {
      header: 'Passwort ändern',
      currentPassword: 'Aktuelles Passwort eingeben',
      newPassword: 'Neues Passwort eingeben',
      newPasswordConfirm: 'Neues Passwort bestätigen',
      confirmPasswordError:
        'Die Eingaben für das neue Passwort stimmen nicht überein.',
      submitButton: 'Passwort ändern',
      changeError:
        'Dein aktuelles Passwort ist leer oder ungültig. Die Angabe ist notwendig, um das Passwort zu ändern.',
      success: 'Dein Passwort wurde erfolgreich geändert.',
      hint: 'Aus Sicherheitsgründen empfehlen wir dein Passwort zu ändern.',
      showPassword: 'Passwort einblenden'
    },
    passwordResetForm: {
      header: 'Wiederherstellung deines Passwortes',
      newPassword: 'Neues Passwort eingeben',
      newPasswordConfirmation: 'Neues Passwort bestätigen',
      submitButton: 'Neues Passwort speichern',
      success: 'Dein Passwort wurde erfolgreich geändert',
      confirmationError:
        'Die eingegebene Werte stimmen nicht überein, bitte versuche es erneut',
      error:
        'Es gab einen Fehler bei der Verarbeitung deiner Daten, bitte versuche es erneut',
      redirect: 'Hier geht es zum Login'
    },
    emailForm: {
      reasonHeader: 'E-Mail-Adresse eingeben',
      reason1:
        'um dein Passwort wiederherzustellen,\nfalls du es vergessen hast',
      reason2:
        'für Benachrichtigungen bei Aktualisierungen,\n z.B. von Empfehlungen für Arzneimitteltherapie',
      header: 'E-Mail-Adresse eingeben',
      email: 'E-Mail-Adresse eingeben',
      emailConfirm: 'E-Mail-Adresse bestätigen',
      password: 'Passwort eingeben',
      submitButton: 'E-Mail-Adresse speichern',
      error:
        'Deine E-Mail-Adresse konnte nicht geändert werden. Bitte versuche es erneut.',
      success: 'E-Mail-Adresse erfolgreich geändert!',
      confirmEmailError:
        'Die E-Mail-Adresse und die Bestätigung stimmen nicht überein',
      skipButton: 'Überspringen'
    },
    requestPasswordResetForm: {
      header: 'Passwort zurücksetzen',
      description:
        'Gib deinen Nutzernamen ein und wir werden dir eine E-Mail mit weiteren Anweisungen senden.',
      username: 'Benutzername eingeben',
      usernameConfirmation: 'Benutzername bestätigen',
      submitButton: 'Absenden',
      cancel: 'Abbrechen und zurück zum Login',
      error:
        'Der eingegebene Nutzer existiert nicht oder er hat keine zugewiesene E-Mail-Adresse.',
      success:
        'Wir haben weitere Anweisungen an deine E-Mail-Adresse gesendet.',
      confirmationError:
        'Die Eingaben stimmen nicht überein. Bitte versuche es erneut.'
    },
    languageForm: {
      header: 'Sprache',
      error: 'Sprache konnte nicht geändert werden. Bitte versuche es erneut.',
      de: 'Deutsch',
      en: 'Englisch'
    },
    footer: {
      imprint: 'Impressum',
      terms: 'AGB',
      policy: 'Datenschutzerklärung'
    },
    search: {
      intro:
        'Suche nach Medikamenten und Wirkstoffen,\n um persönliche Empfehlungen zu erhalten.',
      inputText:
        'Medikament, Wirkstoff oder Pharmazentralnummer (PZN) eingeben',
      most_common_search: 'häufig gesuchte Wirkstoffe:',
      autocomplete_no_results: 'Keine Ergebnisse gefunden',
      search_param: 'Du hast folgendes gesucht:',
      search_result_description:
        'Deine Suche lieferte Treffer für folgende Wirkstoffe:',
      favorite_button_add: 'Zu Favoriten hinzufügen',
      favorite_button_remove: 'Aus Favoriten entfernen',
      search_results_empty: `Für diesen Wirkstoff liegen aktuell keine Therapieempfehlungen vor, die auf Kenntnissen von DNA-Varianten basieren.`,
      fetching: 'Ergebnisse werden geladen, bitte warten...',
      legend: {
        button: 'Was bedeuten die Symbole?',
        text: {
          good:
            'Deine DNA-Varianten beeinflussen die Wirkung dieser Substanz nicht.',
          alert:
            'Für die Wirkung dieser Substanz sind deine DNA-Varianten relevant.\n Beachte die Empfehlungen hierzu.'
        },
        close: 'Schließen'
      },
      sections: {
        recommendation: 'Empfehlung',
        gene_info: 'Gen',
        background_info: 'Fachinformation',
        references: 'Literatur'
      },
      no_info: 'Diese Funktion steht demnächst zur Verfügung.',
      clear_search: 'Suche zurücksetzen',
      active_ingredient_name: 'Wirkstoff',
      drug_name: 'Medikament',
      gene: 'Gen',
      pzn: 'PZN'
    },
    help: {
      text: 'Noch Fragen? So sind wir erreichbar:',
      tel: 'Tel.',
      fax: 'Fax.'
    },
    searchResultItem: {
      myResult: 'meine DNA-Variante',
      viewMore: 'mehr erfahren',
      viewLess: 'schließen'
    },
    tutorial: {
      openModal: 'Tutorial Pharmakogenetik',
      title: 'Tutorial',
      closeButton: 'Schließen',
      prev: 'Zurück',
      next: 'Weiter',
      slide1: {
        title:
          'Überblick: Gene, DNA-Varianten, Proteine und die Wirkung von Medikamenten',
        subTitle: 'Gene',
        list:
          'sind Abschnitte der DNA \nenthalten die Bauanleitung zur Herstellung von Proteinen'
      },
      slide2: {
        title: 'Der Weg eines Medikamentes durch den Körper',
        list:
          'bestimmte Proteine sind wichtig für die Wirkung von Medikamenten'
      },
      slide3: {
        title: 'DNA-Varianten',
        list:
          'individuelle DNA-Varianten in Genen beeinflussen die Funktion dieser Proteine'
      },
      slide4: {
        title: 'Auswirkungen von DNA-Varianten auf Medikamente',
        list:
          'somit können Medikamente bei verschiedenen Menschen unterschiedliche Effekte haben\nsie können toxisch, zu stark oder gar nicht wirken'
      }
    },
    report: {
      personalizeButton: 'Bericht erstellen',
      reportInfo: `<p>
        Damit ein Arzt deinen Befundbericht mit den darin enthaltenen Empfehlungen für klinische Entscheidungen verwenden kann, sind vor dem Ausdrucken in das erscheinende Formular persönliche Angaben einzugeben.</p>
        <p>Der Bericht ist nur für die Person autorisiert, deren Analyseergebnisse in diesem Account berichtet werden.</p>`,
      printJobStatusPending: 'Befundbericht wird erstellt...',
      printJobStatusReady: 'Befundbericht steht zur Verfügung.',
      printJobStatusError:
        'Befundbericht konnte nicht erstellt werden. Bitte versuche es zu einem späteren Zeitpunkt noch einmal.',
      printJobStatusExpired:
        'Zeitüberschreibung bei der Verarbeitung des Auftrages. Aus Gründen des Datenschutzes werden personalisierte Befundberichte nach 30 Sekunden gelöscht. Bitte erstelle einen neuen Befundbericht.',
      reportIsGenerating: `Dein Befundbericht wird erstellt und steht in Kürze bereit. Bitte beachte, 
      dass das PDF dann für 30 Sekunden zum Download verfügbar ist. 
      Nach Ablauf dieser Zeit werden das Dokument sowie deine persönlichen Daten gelöscht.`,
      noReportFound: '<p>Keine Befundberichte verfügbar.</p>',
      noPersonalizationFields:
        'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Kundensupport.',
      sendButton: 'Senden',
      downloadButton: 'Hier herunterladen'
    },
    favorites: {
      loadingMessage: 'Deine Favoritenliste wird geladen',
      empty: 'Du hast noch keine Favoriten gespeichert',
      editButton: 'Anpassen',
      finishButton: 'Anpassung beenden',
      drugs: 'Wirkstoffe/Medikamente',
      genes: 'Gene',
      actions: {
        remove: {
          label: 'Entfernen',
          errorMessage:
            'Favoriteneintrag konnte nicht entfernt werden. Versuche es später erneut.'
        }
      }
    },
    recommendation: {
      suggestionText:
        'basierend auf deiner Auswahl wurden folgende Hinweise für den Wirkstoff <span class="emphasized">‚{activeIngredient}‘</span> gefunden',
      notice:
        'Dieser Befund kann eine Entscheidung oder Beurteilung, die im persönlichen Kontakt mit einem Arzt getroffen wird, nicht ersetzen.\n Das Absetzen einer Medikation oder eine Änderung der Dosierung ohne Rücksprache mit einem Arzt kann mit Risiken verbunden sein.'
    },
    myDna: {
      noContent: 'Keine Inhalte verfügbar.',
      descriptions: {
        genes:
          'Gene mit bei dir nachgewiesenen DNA-Varianten und deren Bedeutung für Arzneimitteltherapien.',
        drugs: 'Wirkstoffe, für die persönliche Dosisempfehlungen vorliegen.',
        results: 'Alle Ergebnisse meiner Laboranalysen.'
      },
      loadingMessage: 'Daten werden geladen'
    },
    information: {
      loadingMessage: 'Daten werden geladen'
    },
    feedback: {
      openModalButton: 'Feedback senden',
      title: 'Feedback zur App',
      rating: {
        awesome: 'Unglaublich!',
        good: 'Gut',
        meh: 'Meh!',
        bad: 'Schlecht',
        horrible: 'Grauenhaft!'
      },
      rateThisPage: 'Bewerte diese Seite',
      whatDoYouLike:
        'Wenn dir diese APP gefällt, sag es weiter - gern auch uns! ',
      whatNeedsToBeImproved:
        'Lass uns wissen, wo wir uns noch verbessern können.',
      submitButton: 'Absenden',
      cancelButton: 'Schließen',
      statusError: {
        requiredFields:
          'Das Formular konnte nicht versendet werden, weil nicht alle Pflichtfelder gefüllt sind.',
        requestError: 'Fehler beim Absenden! Bitte versuche es später wieder.'
      },
      statusSuccess: 'Danke für dein Feedback!',
      requiredField: 'Pflichtfeld'
    },
    patientScopeSwitcher: {
      inputText: 'Patienten ID'
    }
  }
};
