import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import Autocomplete from '../../components/Autocomplete';
import SearchResultList from './components/SearchResultList';

class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterItems: [] // items in filter will be hidden from view
    };
  }

  resetFilter = () => {
    this.setState({ filterItems: [] });
  };

  /**
   * Adds or removes an item from the filter array
   */
  toogleFilter = filterItem => {
    this.setState(state => {
      if (this.isInFilter(filterItem)) {
        // remove item from filter
        return {
          filterItems: state.filterItems.filter(item => item !== filterItem)
        };
      } else {
        // add item to filter
        return { filterItems: [...state.filterItems, filterItem] };
      }
    });
  };

  isInFilter = item => {
    if (this.state.filterItems.indexOf(item) >= 0) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <Container className="search-results">
        <Row className="no-gutters">
          <Col>
            <Autocomplete onSelect={this.resetFilter} 
             navigate={this.props.navigate}
             location={this.props.location}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SearchResultList
              filterItems={this.state.filterItems}
              toggleFilter={this.toogleFilter}
              isInFilter={this.isInFilter}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default SearchResults;
