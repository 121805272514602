import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Autosuggest from 'react-autosuggest';
import { Close, Search } from 'grommet-icons';
import { FormGroup, Input, InputGroup } from 'reactstrap';
import { apiService, stateService } from '../../services';

export class Autocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      label: '',
      type: '',
      suggestionOpen: false
    };

    this.timeout = undefined;
    this.searchInput = null;
  }

  componentDidMount() {
    // get predefined search value from url

    const urlParams = new URLSearchParams(window.location.search);
    const searchParam = urlParams.get('searchParam') || '';
    const searchLabel = urlParams.get('searchLabel') || '';
    const searchType = urlParams.get('searchType') || '';

    this.setState(
      {
        value: searchParam,
        label: searchLabel,
        type: searchType
      },
      () => {
        if (!this.state.value) {
          // clear search input value and remove search results if no param in URL
          this.props.clearSearchSuggestions();
          this.props.clearSearch();
        } else {
          // trigger search only if search params have changed
          if (
            !this.props.isFetching &&
            (!this.props.searchItem ||
              searchParam !== this.props.searchItem.value ||
              searchLabel !== this.props.searchItem.label ||
              searchType !== this.props.searchItem.type)
          ) {
            // fire search if search param is already prefilled in URL
            this.loadRecommendations({
              value: this.state.value,
              label: this.state.label,
              type: this.state.type
            });
          }
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.patientScope.patient_id !== prevProps.patientScope.patient_id
    ) {
      this.loadRecommendations({
        value: this.state.value,
        label: this.state.label,
        type: this.state.type
      });
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  setFocus = () => {
    // set the focus on the autosuggest input element after component mounted
    if (this.searchInput) {
      this.searchInput.focus();
    }
  };

  clearSearch = () => {
    let params = {};

    let location = window.location.href;
    const patient =
        new URLSearchParams(location.search).get('patient') || '';

    if (patient) {
      params.patient = patient;
    }


    if (this.props.navigate && this.props.location) {
      const finalParams = new URLSearchParams(params).toString();
      const pathname = this.props.location.pathname;
      this.props.navigate(
        finalParams ? `${pathname}?${finalParams}` : pathname
      );
    } else {
      window.location.href = location + "?" + new URLSearchParams(params).toString();
    }

    this.props.clearSearch();
    this.setState({
      value: '',
      label: '',
      type: ''
    });
    this.setFocus();
  };

  onChange = event => {
    this.setState({
      value: event.target.value,
      label: event.target.value,
      type: event.target.value,
      suggestionOpen: true
    });
  };

  onBlur = () =>
    this.setState({
      suggestionOpen: false
    });

  onMouseOut = () => {
    this.timeout = setTimeout(this.onBlur, 1000);
  };

  loadRecommendations = selectedItem =>
    this.props.getRecommendationsBySearchParam(selectedItem, this.props.lang);
  loadAutocomplete = value =>
    this.props.getAutocomplete(value, this.props.lang);

  onSelect = (event, { suggestion }) => {
    if (this.props.onSelect) {
      this.props.onSelect();
    }

    let params = {};
    let location = window.location.href;
    const url = new URL(location);
    const patient = url.searchParams.get('patient') || '';

    if (patient) {
      params.patient = patient;
    }

    const { label, value, result_type } = suggestion;
    console.log(suggestion)
    this.setState(
      { value, label, type: result_type, suggestionOpen: false },
      () => {
        this.loadRecommendations({
          value: this.state.value,
          label: this.state.label,
          type: this.state.type
        });
        // update url to match search
        // alert('update url' + value)
        const paramValues = new URLSearchParams({
          ...params,
          searchParam: value,
          searchLabel: label,
          searchType: result_type
        });

        this.props.navigate(`/search/results?${paramValues}`)
      }
    );
  };

  renderSuggestion = suggestion => {
    return (
      <div>
        <div className="font-weight-bold">{suggestion.label}</div>
        <div>
          <FormattedMessage id={`search.${suggestion.result_type}`} />
        </div>
      </div>
    );
  };

  renderInputComponent = inputProps => (
    <InputGroup>
      <Search className="searchIcon" />
      <Input name="drugSearch" {...inputProps} />
      <Close className="clearIcon" onClick={this.clearSearch} />
    </InputGroup>
  );

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const { suggestionOpen } = this.state;
    const { isFetching, autocompleteData } = this.props;

    if (
      !isFetching &&
      suggestionOpen &&
      query.length > 2 &&
      autocompleteData.length <= 0
    ) {
      return (
        <div {...containerProps}>
          <div className="react-autosuggest__suggestion-list--empty">
            <FormattedMessage id="search.autocomplete_no_results" />
          </div>
        </div>
      );
    }
    return <div {...containerProps}>{children}</div>;
  };

  getSuggestionValue = suggestion => {
    return suggestion.label;
  };

  render() {
    const { label } = this.state;
    const { intl, autocompleteData, clearSearchSuggestions } = this.props;

    const inputProps = {
      placeholder: intl.formatMessage({ id: 'search.inputText' }),
      value: label,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onMouseOut: this.onMouseOut,
      autoFocus: true
    };

    return (
        <FormGroup className="autocomplete">
          <Autosuggest
              suggestions={autocompleteData}
              onSuggestionsFetchRequested={this.loadAutocomplete}
              onSuggestionsClearRequested={clearSearchSuggestions}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              renderInputComponent={this.renderInputComponent}
              renderSuggestionsContainer={this.renderSuggestionsContainer}
              ref={(autosuggest) => {
                if (autosuggest !== null) {
                  this.searchInput = autosuggest.input;
                }
              }}
              inputProps={inputProps}
              onSuggestionSelected={this.onSelect}
              highlightFirstSuggestion={true}
              focusInputOnSuggestionClick={false}
          />
        </FormGroup>
    );
  }
}

Autocomplete.propTypes = {
  autocompleteData: PropTypes.array.isRequired,
  searchResults: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  lang: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  clearSearchSuggestions: PropTypes.func.isRequired,
  clearSearch: PropTypes.func,
  getAutocomplete: PropTypes.func,
  searchItem: PropTypes.object,
  getRecommendationsBySearchParam: PropTypes.func,
  onSelect: PropTypes.func,
  patientScope: PropTypes.object
};

const mapStateToProps = state => {
  return {
    autocompleteData: state.autocomplete.autocompleteData,
    isFetching: state.autocomplete.isFetching,
    searchResults: state.search.searchResults,
    searchItem: state.search.searchItem,
    lang: state.lang.lang,
    patientScope: state.patientScopeSwitcher.patientScope
  };
};

export default (
  connect(mapStateToProps, {
    getAutocomplete: apiService.getAutocomplete,
    getRecommendationsBySearchParam: apiService.getRecommendationsBySearchParam,
    clearSearchSuggestions: stateService.clearSearchSuggestions,
    clearSearch: stateService.clearSearch
  })(injectIntl(Autocomplete))
);
