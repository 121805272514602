import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';

const Overlay = ({ isFetching, restrictToPath, messageId }) => {
  const location = useLocation();
  const showOverlay = isFetching === true && location.pathname === restrictToPath;

  return (
    <div className="loading-overlay" style={{ display: showOverlay ? 'block' : 'none' }}>
      <LoadingSpinner messageId={messageId} />
    </div>
  );
};

Overlay.propTypes = {
  restrictToPath: PropTypes.string.isRequired,
  messageId: PropTypes.string,
  isFetching: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isFetching: state.user.isFetching || state.auth.isFetching
  };
};

export default connect(mapStateToProps)(Overlay);
