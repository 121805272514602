import { jsonrpc as api } from '@brightsolutionsgmbh/client-core';

export const AUTOCOMPLETE_REQUEST = '@@patientScope/AUTOCOMPLETE_REQUEST';
export const AUTOCOMPLETE_SUCCESS = '@@patientScope/AUTOCOMPLETE_SUCCESS';
export const AUTOCOMPLETE_ERROR = '@@patientScope/AUTOCOMPLETE_ERROR';

export const getAutocomplete = searchParam => {
  let body = {
    jsonrpc: '2.0',
    method: 'dm_user.patientIdsAutocomplete',
    params: {
      user_input: searchParam.value || searchParam
    },
    id: 'my-id'
  };

  return api.request(
    'POST',
    '',
    [AUTOCOMPLETE_REQUEST, AUTOCOMPLETE_SUCCESS, AUTOCOMPLETE_ERROR],
    body
  );
};

export const GETSCOPE_REQUEST = '@@patientScope/GETSCOPE_REQUEST';
export const GETSCOPE_SUCCESS = '@@patientScope/GETSCOPE_SUCCESS';
export const GETSCOPE_ERROR = '@@patientScope/GETSCOPE_ERROR';

export const getPatientScope = () => {
  let body = {
    jsonrpc: '2.0',
    method: 'dm_user.getPatientScope',
    params: {},
    id: 'my-id'
  };

  return api.request(
    'POST',
    '',
    [GETSCOPE_REQUEST, GETSCOPE_SUCCESS, GETSCOPE_ERROR],
    body
  );
};

export const SETSCOPE_REQUEST = '@@patientScope/SETSCOPE_REQUEST';
export const SETSCOPE_SUCCESS = '@@patientScope/SETSCOPE_SUCCESS';
export const SETSCOPE_ERROR = '@@patientScope/SETSCOPE_ERROR';

export const setPatientScope = patient_id => {
  let body = {
    jsonrpc: '2.0',
    method: 'dm_user.setPatientScope',
    params: {
      patient_id
    },
    id: 'my-id'
  };

  return api.request(
    'POST',
    '',
    [SETSCOPE_REQUEST, SETSCOPE_SUCCESS, SETSCOPE_ERROR],
    body
  );
};

export const CLEAR_SUGGESTIONS = '@@patientScope/CLEAR_SUGGESTIONS';

export const clearSuggestions = () => {
  return {
    type: CLEAR_SUGGESTIONS
  };
};
