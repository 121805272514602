import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Input,
    InputGroup,
    InputGroupText,
    Form,
    FormGroup,
    Label
} from 'reactstrap';
import SubmitButton from '../../components/Others/SubmitButton';
import { FormattedMessage } from 'react-intl';
import eye from '../../images/eye.svg';
import eyeSlash from '../../images/eye-slash.svg';

export class LogInForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: props.username || '',
            password: '',
            isPasswordVisible: false,
            isFormSubmitted: false
        };
    }

    handleChange = ev => {
        this.setState({
            [ev.target.id]: ev.target.value
        });
    };

    handleSubmit = ev => {
        ev.preventDefault();
        this.setState({
            isFormSubmitted: true
        });
        const { username, password } = this.state;
        this.props.logIn(username, password, this.props.queryParams);
    };

    togglePassword = () => {
        this.setState(prevState => ({
            isPasswordVisible: !prevState.isPasswordVisible
        }));
    };

    render() {
        // allows to show/hide the entered password in the input field
        const passwordInputType = this.state.isPasswordVisible
            ? 'text'
            : 'password';

        return (
            <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                    <Label>
                        <FormattedMessage
                            id="loginForm.username"
                            defaultMessage="Username"
                        />
                    </Label>
                    <Input
                        id="username"
                        value={this.state.username}
                        onChange={this.handleChange}
                        bsSize="lg"
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label>
                        <FormattedMessage
                            id="loginForm.password"
                            defaultMessage="Password"
                        />
                    </Label>

                    <InputGroup>
                        <Input
                            type={passwordInputType}
                            id="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            bsSize="lg"
                            required
                        />
                        <div className="input-group-append">
                            <div
                                id="showPassword"
                                onClick={this.togglePassword}
                            >
                                <InputGroupText className="login-icon-eye">
                                    {passwordInputType === 'password' ? (
                                        <img alt="svg-icon" src={eyeSlash} width="30" />
                                    ) : (
                                        <img alt="icon2" src={eye} width="30" />
                                    )}
                                </InputGroupText>
                            </div>
                        </div>
                    </InputGroup>
                </FormGroup>
                <SubmitButton
                    isLoading={this.props.isLoading}
                    block
                    id="loginButton"
                    size="lg"
                >
                    <FormattedMessage id="loginForm.loginButton" defaultMessage="Login" />
                </SubmitButton>
            </Form>
        );
    }
}

LogInForm.propTypes = {
    /** The submit function. */
    logIn: PropTypes.func.isRequired,
    /** An optional prefilled username. */
    username: PropTypes.string,
    /** Optional query parameters as a plain javascript object */
    queryParams: PropTypes.object,
    isLoading: PropTypes.bool
};

export default LogInForm;
