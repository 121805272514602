export default function getDevice() {
  let device = null;

  if (navigator.userAgent.toLocaleLowerCase().indexOf('android') > -1) {
    device = 'android';
  }

  if (
    navigator.userAgent.toLocaleLowerCase().indexOf('iphone') > -1 ||
    navigator.userAgent.toLocaleLowerCase().indexOf('ipad') > -1
  ) {
    device = 'iOS';
  }

  return device;
}
