import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Checkmark, Close } from 'grommet-icons';
import SearchResultItem from '../../../../components/SearchResultItem';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import classNames from 'classnames';
import FavoriteStar from '../../../../components/FavoriteStar';
import { Legend } from '../../../../components/Legend';

export class SearchResultList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showError: false
    };
  }

  render() {
    const {
      searchResults,
      searchItem,
      isFetching,
      isInFilter,
      toggleFilter,
      intl
    } = this.props;

    let render = '';
    if (searchResults.length > 0) {
      let activeIngredientList = [];
      let recommendationStatusList = [];
      searchResults.forEach(result => {
        activeIngredientList.push(result.active_ingredient_name);
        recommendationStatusList.push(result.recommendation_status);
      });
      activeIngredientList = [...new Set(activeIngredientList)];
      recommendationStatusList = [...new Set(recommendationStatusList)];

      let genesList = searchResults.map(result => result.gene_name);
      genesList = [...new Set(genesList)];

      const favorite = {
        name: searchItem.label,
        type: searchItem.type,
        value: {
          value: searchItem.value,
          recommendation_status: recommendationStatusList.includes(
            'action_required'
          )
            ? 'action_required'
            : recommendationStatusList[0]
        },
        genes: genesList.map(geneName => ({
          name: geneName,
          type: 'gene',
          value: { value: geneName }
        }))
      };

      const toolbar = (
        <div className="search-toolbar">
          <Row className="search-toolbar-module">
            <Col>
              <FavoriteStar
                favorite={favorite}
                labelAdd={intl.formatMessage({
                  id: 'search.favorite_button_add'
                })}
                labelRemove={intl.formatMessage({
                  id: 'search.favorite_button_remove'
                })}
                labelError={intl.formatMessage({
                  id: 'favorites.actions.remove.errorMessage'
                })}
              />
            </Col>
          </Row>
          <Row className="search-toolbar-module d-flex align-items-center">
            <Col>
              <FormattedMessage id="search.search_result_description" />
              {activeIngredientList.map((aiName, index) => (
                <Button
                  key={index}
                  size="sm"
                  color="link"
                  className={classNames('active-ingredient', {
                    deselected: isInFilter(aiName)
                  })}
                  onClick={() => toggleFilter(aiName)}
                >
                  {isInFilter(aiName) ? <Close /> : <Checkmark />}
                  {aiName}
                </Button>
              ))}
            </Col>
          </Row>
        </div>
      );

      const results = searchResults.map((result, index) => {
        // hide items with active ingredients found in the filter array
        return (
          !isInFilter(result.active_ingredient_name) && (
            <SearchResultItem key={index} data={result} />
          )
        );
      });

      render = (
        <>
          {toolbar}
          {results}
          <Legend />
        </>
      );
    } else {
      render = (
        <div className="text-center py-5">
          <FormattedMessage id="search.search_results_empty" />
        </div>
      );
    }

    const fetchingMessage = <LoadingSpinner messageId="search.fetching" />;

    return (
      <div className="search-result-list">
        {isFetching ? fetchingMessage : render}
      </div>
    );
  }
}

SearchResultList.propTypes = {
  searchResults: PropTypes.array.isRequired,
  searchItem: PropTypes.object,
  isFetching: PropTypes.bool,
  user: PropTypes.object.isRequired,
  filterItems: PropTypes.array.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  isInFilter: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    searchResults: state.search.searchResults,
    searchItem: state.search.searchItem,
    isFetching: state.search.isFetching,
    user: state.user.data
  };
};

export default injectIntl(connect(mapStateToProps)(SearchResultList));
