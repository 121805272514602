import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Input,
  FormGroup,
  Label,
  UncontrolledAlert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { apiService } from '../../services';
import { SubmitButton } from '@brightsolutionsgmbh/biologis-react-components';

export class PasswordForm extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      isPasswordVisible: false
    };

    this.state = {
      ...this.initialState,
      isFormSubmitted: false,
      isNewPasswordConfirmed: false
    };
  }

  handleChange = ev => {
    this.setState({
      [ev.target.id]: ev.target.value
    });
  };

  changePassword = async () => {
    const { isFirstTime = false } = this.props;
    try {
      await this.props.changeUserPassword(
        this.props.userId,
        this.state.currentPassword,
        this.state.newPassword,
        isFirstTime
      );

      // if success, reset form fields
      if (!this.props.error) {
        this.setState({ ...this.initialState });
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { newPassword, newPasswordConfirm } = this.state;

    this.setState(
      {
        isFormSubmitted: false
      },
      () => {
        if (newPassword !== '' && newPassword === newPasswordConfirm) {
          this.setState({
            isFormSubmitted: true,
            isNewPasswordConfirmed: true
          });
          this.changePassword();
        } else {
          this.setState({
            isFormSubmitted: true,
            isNewPasswordConfirmed: false
          });
        }
      }
    );
  };

  togglePassword = () => {
    this.setState(prevState => ({
      isPasswordVisible: !prevState.isPasswordVisible
    }));
  };

  render() {
    const { error, isFetching, children } = this.props;
    const { isNewPasswordConfirmed, isFormSubmitted } = this.state;

    const passwordInputType = this.state.isPasswordVisible
      ? 'text'
      : 'password';

    return (
      <div className="form-wrapper">
        <Form onSubmit={this.handleSubmit}>
          <Card className="password-form">
            <CardHeader>
              <FormattedMessage id="passwordForm.header" />
            </CardHeader>
            <CardBody>
              {children}
              <FormGroup>
                <Label for="currentPassword">
                  <FormattedMessage id="passwordForm.currentPassword" />
                </Label>
                <Input
                  id="currentPassword"
                  type={passwordInputType}
                  value={this.state.currentPassword}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="newPassword">
                  <FormattedMessage id="passwordForm.newPassword" />
                </Label>
                <Input
                  id="newPassword"
                  type={passwordInputType}
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="newPasswordConfirm">
                  <FormattedMessage id="passwordForm.newPasswordConfirm" />
                </Label>
                <Input
                  id="newPasswordConfirm"
                  type={passwordInputType}
                  value={this.state.newPasswordConfirm}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup check className="my-3">
                <Label check>
                  <Input
                    type="checkbox"
                    id="showPassword"
                    onChange={this.togglePassword}
                    checked={this.state.isPasswordVisible}
                  />{' '}
                  <FormattedMessage id="passwordForm.showPassword" />
                </Label>
              </FormGroup>
              {!isFetching && (
                <>
                  {isFormSubmitted && isNewPasswordConfirmed && error && (
                    <UncontrolledAlert color="danger">
                      <FormattedMessage id="passwordForm.changeError" />
                    </UncontrolledAlert>
                  )}
                  {isFormSubmitted && !isNewPasswordConfirmed && (
                    <UncontrolledAlert color="danger">
                      <FormattedMessage id="passwordForm.confirmPasswordError" />
                    </UncontrolledAlert>
                  )}
                  {isFormSubmitted && isNewPasswordConfirmed && !error && (
                    <UncontrolledAlert color="success">
                      <FormattedMessage id="passwordForm.success" />
                    </UncontrolledAlert>
                  )}
                </>
              )}
            </CardBody>
            <CardFooter className="text-right">
              <SubmitButton isLoading={isFetching}>
                <FormattedMessage id="passwordForm.submitButton" />
              </SubmitButton>
            </CardFooter>
          </Card>
        </Form>
      </div>
    );
  }
}

PasswordForm.propTypes = {
  changeUserPassword: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  error: PropTypes.bool,
  isFetching: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isFirstTime: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    userId: state.user.data.id,
    error: state.user.error,
    isFetching: state.user.isFetching
  };
};

export default connect(mapStateToProps, {
  changeUserPassword: apiService.changeUserPassword
})(PasswordForm);
