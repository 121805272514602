import {
  AUTOCOMPLETE_REQUEST,
  AUTOCOMPLETE_ERROR,
  AUTOCOMPLETE_SUCCESS,
  GETSCOPE_REQUEST,
  GETSCOPE_ERROR,
  GETSCOPE_SUCCESS,
  SETSCOPE_REQUEST,
  SETSCOPE_ERROR,
  SETSCOPE_SUCCESS,
  CLEAR_SUGGESTIONS
} from '../actions/patientScopeSwitcher';

const initState = {
  autocompleteData: [],
  isFetchingAutocomplete: false,
  error: false,
  patientScope: { isFetching: false, isSetting: false, patient_id: '' }
};

const patientScopeSwitcherReducer = (state = initState, action) => {
  let result;
  switch (action.type) {
    case AUTOCOMPLETE_REQUEST:
      return {
        ...state,
        isFetchingAutocomplete: true,
        error: false
      };
    case AUTOCOMPLETE_ERROR:
      return {
        ...state,
        isFetchingAutocomplete: false,
        error: true
      };
    case AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        isFetchingAutocomplete: false,
        autocompleteData: action.payload.result || [],
        error: false
      };
    case GETSCOPE_REQUEST:
      return {
        ...state,
        patientScope: { ...state.patientScope, isFetching: true },
        error: false
      };
    case GETSCOPE_ERROR:
      return {
        ...state,
        patientScope: { ...state.patientScope, isFetching: false },
        error: true
      };
    case GETSCOPE_SUCCESS:
      return {
        ...state,
        patientScope: {
          ...state.patientScope,
          ...action.payload.result,
          isFetching: false
        },
        error: false
      };
    case SETSCOPE_REQUEST:
      return {
        ...state,
        patientScope: { ...state.patientScope, isSetting: true },
        error: false
      };
    case SETSCOPE_ERROR:
      return {
        ...state,
        patientScope: { ...state.patientScope, isSetting: false },
        error: true
      };
    case SETSCOPE_SUCCESS:
      result = action.payload.result;

      if (!result) {
        result = { patient_id: '' };
      }

      return {
        ...state,
        patientScope: { ...result, isSetting: false },
        error: false
      };
    case CLEAR_SUGGESTIONS:
      return {
        ...state,
        autocompleteData: []
      };
    default:
      return state;
  }
};

export default patientScopeSwitcherReducer;
