import React, { Component } from 'react';
import { Button, Spinner } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class SubmitButton extends Component {
    render() {
        const { isLoading, children, size, color, ...rest } = this.props;

        const spinnerClasses = classNames('mr-2', {
            'align-baseline': size !== 'lg'
        });

        return (
            <Button
                type="submit"
                disabled={isLoading}
                size={size}
                color={color || 'primary'}
                {...rest}
            >
                {isLoading && (
                    <Spinner
                        size={size === 'sm'}
                        className={spinnerClasses}
                        style={{ borderWidth: '0.15rem' }}
                    />
                )}
                <span className={'align-text-bottom'}>{children}</span>
            </Button>
        );
    }
}

SubmitButton.propTypes = {
    isLoading: PropTypes.bool,
    size: PropTypes.string,
    color: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string
    ])
};

export default SubmitButton;
