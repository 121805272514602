import { ReactComponent as MedCheck } from '../images/icons/med-check.svg';
import { ReactComponent as Settings } from '../images/icons/settings.svg';
import { ReactComponent as MyDna } from '../images/icons/dna.svg';
import { ReactComponent as Favorites } from '../images/icons/favorites.svg';
import { ReactComponent as Support } from '../images/icons/support.svg';
import { ReactComponent as Help } from '../images/icons/help.svg';
import { ReactComponent as Pills } from '../images/icons/pills.svg';
import { ReactComponent as Star } from '../images/icons/star.svg';
import { ReactComponent as StarOutline } from '../images/icons/star-outline.svg';
export {
  MedCheck,
  Settings,
  MyDna,
  Favorites,
  Support,
  Help,
  Pills,
  Star,
  StarOutline
};
