import settings from "../utils/settings";

const serviceDeskId = '10';
const requestTypeId = '113';

export const createIssue = ({ rating, likeText, improveText }) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append(
    'Authorization',
    `Basic ${btoa(
      settings.getSetting('servicedesk_user') + ':' + settings.getSetting('servicedesk_password')
    )}`
  );

  const body = {
    serviceDeskId,
    requestTypeId,
    requestFieldValues: {
      summary: 'DM User Feedback',
      description: `*Rating*: ${rating}\n\n{panel:title=What do you like?|borderColor=#ccc| titleBGColor=#c2ffa2|bgColor=#fff}\n${likeText}\n{panel}\n\n{panel:title=What needs to be improved?|borderColor=#ccc| titleBGColor=#faacad|bgColor=#fff}\n${improveText}\n{panel}`
    }
  };

  return fetch('https://support.biologis.com/rest/servicedeskapi/request', {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  });
};
