import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'reactstrap';

const LoadingSpinner = props => (
  <div className="text-center py-5">
    <div className="loading-spinner my-5"> {/* Container for the spinner */}
      <Spinner size="sm" color="primary"  
        style={{
        height: '3rem',
        width: '3rem'}}
    > 
        {' '}
        </Spinner>
    </div>
    <div> {/* Separate container for the message */}
      <FormattedMessage id={props.messageId} />
    </div>
  </div>
);

LoadingSpinner.propTypes = {
  messageId: PropTypes.string.isRequired
};

export default LoadingSpinner;
