import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Carousel, CarouselItem, Button } from 'reactstrap';
import {
  FormattedMessage,
  injectIntl,
} from 'react-intl';

const items = [
  {
    src: 1,
    title: true,
    subTitle: true,
    list: true,
    img: true
  },
  {
    src: 2,
    title: true,
    list: true,
    img: true
  },
  {
    src: 3,
    title: true,
    list: true,
    img: true
  },
  {
    src: 4,
    title: true,
    list: true,
    img: true
  }
];

export class TutorialCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
  }

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = newIndex => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { lang, intl } = this.props;
    const { activeIndex } = this.state;
    const langCode = lang.split('-')[0];

    const slides = items.map((item, index) => {
      let slideNr = index + 1;

      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={index}
        >
          {item.title && (
            <h3>
              <FormattedMessage id={`tutorial.slide${slideNr}.title`} />
            </h3>
          )}
          {item.subTitle && (
            <h4>
              <FormattedMessage id={`tutorial.slide${slideNr}.subTitle`} />
            </h4>
          )}
          {item.text && (
            <p>
              <FormattedMessage id={`tutorial.slide${slideNr}.text`} />
            </p>
          )}
          {item.list && (
            <ul>
              <FormattedMessage id={`tutorial.slide${slideNr}.list`} />
            </ul>
          )}
          {item.img && (
            <div className="text-center">
              <img
                alt={intl.formatMessage({
                  id: `tutorial.slide${slideNr}.title`
                })}
                className="img-fluid"
                src={require(`../../../../images/tutorial/${langCode}/tutorial_0${slideNr}.png`)}
              />
            </div>
          )}
        </CarouselItem>
      );
    });

    return (
      <>
        <Carousel
          className="carousel"
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          interval={false}
        >
          {slides}
        </Carousel>
        <div className="carousel-controls d-flex justify-content-between">
          <Button
            color="primary"
            onClick={this.previous}
            className="control-prev"
          >
            <FormattedMessage id="tutorial.prev" />
          </Button>
          <p>{activeIndex + 1 + '/' + slides.length}</p>
          <Button color="primary" onClick={this.next} className="control-next">
            <FormattedMessage id="tutorial.next" />
          </Button>
        </div>
      </>
    );
  }
}

TutorialCarousel.propTypes = {
  intl: PropTypes.object.isRequired,

  lang: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    lang: state.lang.lang
  };
};

export default injectIntl(connect(mapStateToProps)(TutorialCarousel));
