import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';

class DetailAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0
    };
  }

  setIndex = index => {
    this.setState({
      activeIndex: index
    });
  };

  render() {
    const { activeIndex } = this.state;

    const Tab = ({ field, index }) => {
      const isActive = activeIndex === index;

      return (
        <>
          <h5
            className={classnames('collapse-header', {
              active: isActive
            })}
            onClick={() => this.setIndex(index)}
          >
            <FormattedMessage id={`search.sections.${field.key}`} />
          </h5>
          <Collapse isOpen={isActive}>{field.content}</Collapse>
        </>
      );
    };

    return (
      <div className="recommendation-detail d-lg-none">
        {this.props.fields.map((field, index) => {
          return <Tab key={index} field={field} index={index} />;
        })}
      </div>
    );
  }
}

DetailAccordion.propTypes = {
  fields: PropTypes.array.isRequired,
};

export default DetailAccordion;
