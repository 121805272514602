import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Col, NavItem, Row, Collapse } from "reactstrap";

const NavDropdown = (props) => {
  const location = useLocation();

  const { children, icon, messageId, ...rest } = props;
  const [isActive] = useState(false);
  const [isOpen, setIsOpen] = useState(isActive);

  const togglerId = useMemo(() => messageId.replace(/\W/g, ""), [messageId]); // \W is the equivalent of [^0-9a-zA-Z_])

  const isChildActive = useMemo(
    () =>
      React.Children.map(
        children,
        (child) => child.props.to.split("/")[1]
      ).indexOf(location.pathname.split("/")[1]) >= 0,
    [location.pathname, children]
  );

  return (
    <NavItem active={isChildActive}>
      <span
        className="toggler label"
        onClick={() => setIsOpen(!isOpen)}
        id={togglerId}
        {...rest}
      >
        <Row className="no-gutters">
          {icon && <Col xs="2">{icon}</Col>}
          <Col xs={{ size: "10", offset: icon ? "0" : "2" }}>
            <FormattedMessage id={messageId} />
          </Col>
        </Row>
      </span>
      <Collapse isOpen={isOpen}>
        <Row className="no-gutters">
          <Col xs={{ size: "10", offset: "2" }}>
            <ul className="sub-nav" xs={{ size: "10", offset: "2" }}>
              {children}
            </ul>
          </Col>
        </Row>
      </Collapse>
    </NavItem>
  );
};

export default NavDropdown;
