import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Container} from 'reactstrap';
import {apiService} from '../../services';
import LoadingSpinner from '../../components/LoadingSpinner';
import settings from '../../utils/settings';


class Information extends Component {

  constructor(props) {
    super(props);
    this.state = {
      text: '',
      path: ''
    };
  }

  componentDidMount() {
    const { lang, user, getCustomer, getStaticContent } = this.props;
    if (user.data.relationships) {
      getStaticContent(user.data.relationships.field_customer.data.id, lang);
    } else {
      getCustomer(
          `/dm_customer/dm_customer?filter[field_security_code]=` +
          settings.getSetting('security_code')
      ).then((action) => {
        if (!action.payload.error) {
          getStaticContent(action.payload.data[0].id, lang);
        }
      });
    }
  }


  render() {
    const { staticContent, children } = this.props;
    const contentType = window.location.pathname.split('/', 3).pop()
    let renderContent;

    if (staticContent && staticContent.data.length > 0) {
      renderContent = staticContent.data[0].attributes.field_report_text_sections.find(
          (item) => item.section_name === contentType
      );
    }

    return (
        <>
          {staticContent.isFetching ? (
              <LoadingSpinner messageId="information.loadingMessage" />
          ) : (
              <Container className="ml-0">
                <div className="information p-5">
                  {renderContent && (
                      <div
                          dangerouslySetInnerHTML={{
                            __html: renderContent.processed,
                          }}
                      />
                  )}
                  {children}
                </div>
              </Container>
          )}
        </>
    );
  }
}
Information.propTypes = {
  lang: PropTypes.string,
  user: PropTypes.object.isRequired,
  getCustomer: PropTypes.func.isRequired,
  getStaticContent: PropTypes.func.isRequired,
  staticContent: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state) => {
  return {
    lang: state.lang.lang,
    user: state.user,
    staticContent: state.staticContent,
  };
};

export default connect(mapStateToProps, {
  getCustomer: apiService.getCustomer,
  getStaticContent: apiService.getStaticContent,
})(Information);
