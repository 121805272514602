import biologisLogo from '../images/biologis_gim.svg';

export const requestNotificationPermission = () => {
  if ('Notification' in window) {
    try {
      Notification.requestPermission().then(result => console.log(result));
    } catch (error) {
      // Safari doesn't return a promise for requestPermissions and it
      // throws a TypeError. It takes a callback as the first argument
      // instead.
      if (error instanceof TypeError) {
        Notification.requestPermission(result => {
          console.log(result);
        });
      } else {
        throw error;
      }
    }
  }
};

export const sendNotification = (title, body) => {
  const options = {
    body,
    icon: biologisLogo
  };
  const n = new Notification(title, options);
  setTimeout(n.close.bind(n), 4000);
};
