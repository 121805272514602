import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import LangForm from '../../components/LangForm';
import PasswordForm from '../../components/PasswordForm';
import EmailForm from '../../components/EmailForm';
import { FormattedMessage } from 'react-intl';


class Settings extends Component {
  render() {
    return (

      <Container fluid>
        <div className="settings p-5">
          <Row>
            <Col>
              <h1>
                <FormattedMessage id="navbar.settings" />
              </h1>
            </Col>
          </Row>

          <Row>
            <Col md="12" xl="6">
              <LangForm />
            </Col>
          </Row>
          <Row>
            <Col md="12" xl="6">
              <PasswordForm />
            </Col>
          </Row>
          <Row>
            <Col md="12" xl="6">
              <EmailForm />
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default Settings;
