import React from 'react';
import { Col } from 'reactstrap';
import biologisLogo from '../../images/biologis_gim.svg';

const RightSidebar = () => {
  return (
    <Col lg="2" className="right-sidebar d-flex align-items-end">
      <div className="powered-by">
        <img src={biologisLogo} alt="Powered by bio.logis GIM" />
      </div>
    </Col>
  );
};

export default RightSidebar;
