import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { LinkNext } from 'grommet-icons';
import { Alert, StatusGood } from 'grommet-icons';

const recommendationStats = {
  action_required: <Alert className="status-icon red" />,
  no_action_required: <StatusGood className="status-icon green" />,
  no_gene_drug_interaction_known: <StatusGood className="status-icon green" />
};

const ActionButton = ({ label, icon, ...rest }) => {
  const [isActive, updateIsActive] = useState(false);

  const buttonClasses = classNames('action', {
    active: isActive
  });

  return (
    <Button
      color="link"
      className={buttonClasses}
      {...rest}
      onMouseEnter={() => updateIsActive(true)}
      onMouseLeave={() => updateIsActive(false)}
    >
      {icon}
      <div className="label">{label}</div>
    </Button>
  );
};

ActionButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.object
};

const ActionsListItem = React.forwardRef(
  (
    {
      id,
      label,
      link = '/',
      recommendation_status = '',
      actions = [],
      showActions,
      ...rest
    },
    ref
  ) => (
    <div
      className="actions-list-item d-flex jusitfy-content-start flex-full"
      ref={ref}
    >
      {showActions && (
        <div className="actions-list-actions">
          {actions.map(({ callback, ...rest }, index) => (
            <ActionButton
              onClick={() => callback(id, ref)}
              {...rest}
              key={index}
            />
          ))}
        </div>
      )}
      <ListGroupItem {...rest} className="flex-fill">
        <NavLink to={link}>
          <LinkNext />
          {(recommendation_status &&
            recommendationStats[recommendation_status]) ||
            ''}
          <span className="label">{label}</span>
        </NavLink>
      </ListGroupItem>
    </div>
  )
);

ActionsListItem.propTypes = {
  id: PropTypes.string,
  link: PropTypes.string,
  label: PropTypes.string,
  actions: PropTypes.array,
  showActions: PropTypes.bool
};

const ActionsList = ({ listItems, actions, showActions, ...rest }) => (
  <ListGroup className="linked-list actions-list" {...rest}>
    {listItems.map(item => {
      const ref = React.createRef();
      return (
        <ActionsListItem
          key={item.id || item.label}
          ref={ref}
          {...item}
          actions={actions}
          showActions={showActions}
        />
      );
    })}
  </ListGroup>
);

ActionsList.propTypes = {
  listItems: PropTypes.array,
  actions: PropTypes.array,
  showActions: PropTypes.bool
};

export default ActionsList;
