import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import getDevice from '../../../../utils/device';

class GetApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      android: 'android',
      iOS: 'iOS'
    };
  }

  getApp = () => {
    const { android, iOS } = this.state;

    if (getDevice() === android) {
      window.open(
        'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.biologis.pharmasensor',
        '_blank'
      );
    }

    if (getDevice() === iOS) {
      window.open(
        'https://itunes.apple.com/de/app/pharma-sensor/id1237522004?mt=8',
        '_blank'
      );
    }
  };

  render() {
    const { android } = this.state;

    return (
      <div className="get-app-banner">
        <button onClick={this.getApp}>
          <FormattedMessage
            id={getDevice() === android ? 'app.getAndroidApp' : 'app.getiOSApp'}
          />
        </button>
      </div>
    );
  }
}

export default GetApp;
