import React, { Component } from 'react';
import {
  Col,
  Row,
  Container,
  Form,
  Input,
  FormGroup,
  Label,
  UncontrolledAlert
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Footer from '../../components/Footer';
import LanguageSwitcher from '../../components/LangSwitcher';
import RightSidebar from '../../components/RightSidebar';
import { apiService, stateService } from '../../services';
import { SubmitButton } from '@brightsolutionsgmbh/biologis-react-components';

export class RequestPasswordResetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      usernameConfirmation: '',
      isUsernameConfirmed: false,
      isFormSubmitted: false
    };
  }

  handleChange = ev => {
    this.setState({
      [ev.target.id]: ev.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState(
      {
        isFormSubmitted: false
      },
      () => {
        if (
          this.state.username !== '' &&
          this.state.username === this.state.usernameConfirmation
        ) {
          this.setState({
            isFormSubmitted: true,
            isUsernameConfirmed: true
          });
          this.props.requestNewUserPassword(this.state.username);
        } else {
          this.setState({
            isFormSubmitted: true,
            isUsernameConfirmed: false
          });
        }
      }
    );
  };

  render() {
    const { error, isFetching } = this.props;
    const {
      username,
      usernameConfirmation,
      isUsernameConfirmed,
      isFormSubmitted
    } = this.state;

    return (
      <Container>
        <Row>
          <Col
            lg="10"
            className="d-flex flex-column justify-content-between align-items-center"
          >
            <LanguageSwitcher
              handleLangChange={lang => this.props.setLangLocally(lang)}
            />
            <div className="reset-password-form">
              <h3>
                <FormattedMessage id="requestPasswordResetForm.header" />
              </h3>
              <p>
                <FormattedMessage id="requestPasswordResetForm.description" />
              </p>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="username">
                    <FormattedMessage id="requestPasswordResetForm.username" />
                  </Label>
                  <Input
                    id="username"
                    type="text"
                    required
                    onChange={this.handleChange}
                    value={username}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="usernameConfirmation">
                    <FormattedMessage id="requestPasswordResetForm.usernameConfirmation" />
                  </Label>
                  <Input
                    id="usernameConfirmation"
                    type="text"
                    required
                    onChange={this.handleChange}
                    value={usernameConfirmation}
                  />
                </FormGroup>
                {!isFetching && (
                  <>
                    {isFormSubmitted && !isUsernameConfirmed && (
                      <UncontrolledAlert color="danger">
                        <FormattedMessage id="requestPasswordResetForm.confirmationError" />
                      </UncontrolledAlert>
                    )}
                    {isFormSubmitted && isUsernameConfirmed && error && (
                      <UncontrolledAlert color="danger">
                        <FormattedMessage id="requestPasswordResetForm.error" />
                      </UncontrolledAlert>
                    )}
                    {isFormSubmitted && isUsernameConfirmed && !error && (
                      <UncontrolledAlert className="success">
                        <FormattedMessage id="requestPasswordResetForm.success" />
                      </UncontrolledAlert>
                    )}
                  </>
                )}
                <Row>
                  <Col>
                    <SubmitButton isLoading={isFetching} size="lg">
                      <FormattedMessage id="requestPasswordResetForm.submitButton" />
                    </SubmitButton>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <Link to="/login">
                      <FormattedMessage id="requestPasswordResetForm.cancel" />
                    </Link>
                  </Col>
                </Row>
              </Form>
            </div>
            <Footer />
          </Col>
          <RightSidebar />
        </Row>
      </Container>
    );
  }
}

RequestPasswordResetForm.propTypes = {
  requestNewUserPassword: PropTypes.func,
  setLangLocally: PropTypes.func,
  error: PropTypes.bool,
  isFetching: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    error: state.user.error,
    isFetching: state.user.isFetching
  };
};

export default connect(
  mapStateToProps,
  {
    requestNewUserPassword: apiService.requestNewUserPassword,
    setLangLocally: stateService.setLangLocally
  }
)(RequestPasswordResetForm);
