import React from 'react';
import PropTypes from 'prop-types';
import { Toast as DefaultToast, ToastBody, ToastHeader } from 'reactstrap';

const Toast = ({ children, onClose, isOpen, ...rest }) => {
  return (
    <DefaultToast {...rest} isOpen={isOpen} className="notification-alert">
      <ToastHeader toggle={onClose} />
      <ToastBody>{children}</ToastBody>
    </DefaultToast>
  );
};

Toast.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ]),
  onClose: PropTypes.func,
  isOpen: PropTypes.bool
};

export default Toast;
