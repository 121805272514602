import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Input,
  FormGroup,
  Button,
  Label,
  UncontrolledAlert
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl'; // Remove 'FormattedHTMLMessage' import
import { apiService } from '../../services';
import { SubmitButton } from '@brightsolutionsgmbh/biologis-react-components';

export class EmailForm extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      email: props.email,
      emailConfirm: '',
      emailCurrentPassword: '',
      isPasswordVisible: false
    };

    this.state = {
      ...this.initialState,
      isFormSubmitted: false,
      isEmailConfirmed: false
    };
  }

  handleChange = ev => {
    this.setState({
      [ev.target.id]: ev.target.value
    });
  };

  resetForm = () => {
    this.setState({
      ...this.initialState,
      email: this.props.email
    });
  };

  changeEmail = async () => {
    const { isFirstTime = false } = this.props;
    try {
      await this.props.setUserEmail(
        this.props.user.id,
        this.state.email,
        this.state.emailCurrentPassword,
        isFirstTime
      );

      // if success, reset form fields
      if (!this.props.error) {
        this.resetForm();
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { email, emailConfirm } = this.state;

    this.setState(
      {
        isFormSubmitted: false
      },
      () => {
        if (email === emailConfirm) {
          this.setState({
            isFormSubmitted: true,
            isEmailConfirmed: true
          });
          this.changeEmail();
        } else {
          this.setState({
            isFormSubmitted: true,
            isEmailConfirmed: false
          });
        }
      }
    );
  };

  handleSkip = () => {
    this.props.skipEmailDialog(this.props.user.id);
  };

  togglePassword = () => {
    this.setState(prevState => ({
      isPasswordVisible: !prevState.isPasswordVisible
    }));
  };

  render() {
    const { user, error, isFetching, children } = this.props;
    const {
      isFormSubmitted,
      isEmailConfirmed,
      email,
      emailConfirm,
      emailCurrentPassword
    } = this.state;

    const passwordInputType = this.state.isPasswordVisible
      ? 'text'
      : 'password';

    return (
      <div className="form-wrapper">
        <Form onSubmit={this.handleSubmit}>
          <Card className="email-form">
            <CardHeader>
              <FormattedMessage id="emailForm.email" />
            </CardHeader>
            <CardBody>
              {children}
              <p className="text-left">
                <FormattedMessage id="emailForm.reasonHeader" />
              </p>
              <ul className="text-left">
                <li>
                  <FormattedMessage id="emailForm.reason1" />
                </li>
                <li>
                  <FormattedMessage id="emailForm.reason2" />
                </li>
              </ul>

              {/* prevent autofill in IE and Firefox */}
              <input
                type="text"
                style={{ visibility: 'hidden', height: '1px' }}
              />
              <input
                type="password"
                style={{ visibility: 'hidden', height: '1px' }}
              />
              <FormGroup>
                <Label for="email">
                  <FormattedMessage id="emailForm.email" />
                </Label>
                <Input
                  id="email"
                  type="text"
                  onChange={this.handleChange}
                  value={email}
                />
              </FormGroup>
              <FormGroup>
                <Label for="emailConfirm">
                  <FormattedMessage id="emailForm.emailConfirm" />
                </Label>
                <Input
                  id="emailConfirm"
                  type="text"
                  onChange={this.handleChange}
                  value={emailConfirm}
                />
              </FormGroup>
              <FormGroup>
                <Label for="emailCurrentpassword">
                  <FormattedMessage id="emailForm.password" />
                </Label>
                <Input
                  id="emailCurrentPassword"
                  type={passwordInputType}
                  onChange={this.handleChange}
                  value={emailCurrentPassword}
                  autoComplete="new-password" // prevent autofill in chrome
                />
              </FormGroup>
              <FormGroup check className="my-3">
                <Label check>
                  <Input
                    type="checkbox"
                    id="showPassword"
                    onChange={this.togglePassword}
                    checked={this.state.isPasswordVisible}
                  />{' '}
                  <FormattedMessage id="passwordForm.showPassword" />
                </Label>
              </FormGroup>
              {!isFetching && (
                <>
                  {isFormSubmitted && !isEmailConfirmed && (
                    <UncontrolledAlert color="danger">
                      <FormattedMessage id="emailForm.confirmEmailError" />
                    </UncontrolledAlert>
                  )}
                  {isFormSubmitted && isEmailConfirmed && error && (
                    <UncontrolledAlert color="danger">
                      <FormattedMessage id="emailForm.error" />
                    </UncontrolledAlert>
                  )}
                  {isFormSubmitted && isEmailConfirmed && !error && (
                    <UncontrolledAlert color="success">
                      <FormattedMessage id="emailForm.success" />
                    </UncontrolledAlert>
                  )}
                </>
              )}
            </CardBody>
            <CardFooter className="text-right">
              {user.attributes && user.attributes.field_needs_email_dialog && (
                <Button
                  outline
                  color="primary"
                  className="mx-2"
                  onClick={this.handleSkip}
                >
                  <FormattedMessage id="emailForm.skipButton"></FormattedMessage>
                </Button>
              )}

              <SubmitButton isLoading={isFetching}>
                <FormattedMessage id="emailForm.submitButton" />
              </SubmitButton>
            </CardFooter>
          </Card>
        </Form>
      </div>
    );
  }
}

EmailForm.propTypes = {
  setUserEmail: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  error: PropTypes.bool,
  email: PropTypes.string,
  isFetching: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  skipEmailDialog: PropTypes.func.isRequired,
  isFirstTime: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    user: state.user.data,
    email: state.user.data.attributes.mail || '',
    error: state.user.error,
    isFetching: state.user.isFetching
  };
};

export default connect(mapStateToProps, {
  setUserEmail: apiService.setUserEmail,
  skipEmailDialog: apiService.skipEmailDialog
})(EmailForm);
