import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

class CookieConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookieConsent: false
    };
  }

  componentDidMount() {
    this.setState({
      cookieConsent: this.checkCookie('cookieConsent')
    });
  }

  handleClick = () => {
    this.setState(
      {
        cookieConsent: true
      },
      () => {
        // expires in 100 days
        this.setCookie('cookieConsent', this.state.cookieConsent, 100, '/');
      }
    );
  };

  setCookie = (cname, cvalue, exdays, cpath) => {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    const path = 'path=' + cpath;
    document.cookie = cname + '=' + cvalue + ';' + expires + ';' + path;
  };

  checkCookie = cname => {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length) === 'true';
      }
    }
    return false;
  };

  render() {
    if (!this.state.cookieConsent) {
      return (
        <div className="cookie-consent">
          <Container>
            <Row>
              <Col md="9">
                <p>
                  <FormattedMessage id="logIn.cookieHint" />
                </p>
              </Col>
              <Col md="3">
                <Button className="warning" onClick={this.handleClick}>
                  <FormattedMessage id="logIn.cookieHintButton" />
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      return '';
    }
  }
}

export default CookieConsent;
