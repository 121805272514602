import React from 'react';
import {
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import { StatusGood, Alert, StatusInfo } from 'grommet-icons';
import { FormattedMessage } from 'react-intl';

export class Legend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  render() {
    return (
      <div className="legend py-4">
        <Button color="link" onClick={this.toggle}>
          <StatusInfo className="grey mr-2" />
          <FormattedMessage id="search.legend.button" />
        </Button>
        <Modal isOpen={this.state.isOpen} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>
            <FormattedMessage id="search.legend.button" />
          </ModalHeader>
          <ModalBody>
            <div className="p-3">
              <Row>
                <Col lg="1">
                  <StatusGood className="status-icon green mx-2 mb-4" />
                </Col>
                <Col>
                  <FormattedMessage id="search.legend.text.good" />
                </Col>
              </Row>
              <Row>
                <Col lg="1">
                  <Alert className="status-icon red mx-2 mb-4" />
                </Col>
                <Col>
                  <FormattedMessage id="search.legend.text.alert" />
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              <FormattedMessage id="search.legend.close" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Legend;
