import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from 'reactstrap';
import classnames from 'classnames';

class DetailTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0
    };
  }

  setIndex = index => {
    this.setState({
      activeIndex: index
    });
  };

  render() {
    const { activeIndex } = this.state;
    const { fields } = this.props;

    return (
      <>
        <div className="recommendation-detail d-none d-lg-block">
          <Nav tabs>
            {fields.map((field, index) => {
              return (
                <NavItem key={field.key}>
                  <NavLink
                    className={classnames({
                      active: `${activeIndex}` === `${index}`
                    })}
                    onClick={() => this.setIndex(index)}
                    disabled={field.disabled}
                  >
                    <FormattedMessage id={`search.sections.${field.key}`} />
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
          <TabContent activeTab={`${activeIndex}`}>
            {fields.map((field, index) => {
              return (
                <TabPane key={field.key} tabId={`${index}`}>
                  <Row>
                    <Col>{field.content}</Col>
                  </Row>
                </TabPane>
              );
            })}
          </TabContent>
        </div>
      </>
    );
  }
}

DetailTabs.propTypes = {
  fields: PropTypes.array.isRequired
};

export default DetailTabs;
