import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Info } from 'grommet-icons';
import TutorialCarousel from './components/TutorialCarousel';
import { apiService } from '../../services';

export class TutorialModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  needsTutorial = () => {
    return (
      this.props.user.attributes &&
      this.props.user.attributes.field_needs_tutorial === true
    );
  };

  componentDidMount() {
    if (this.needsTutorial()) {
      this.toggle();
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleClose = () => {
    if (this.needsTutorial()) {
      this.props.deactivateUserTutorial(this.props.user.id);
    }
  };

  render() {
    return (
      <>
        <span className="label" onClick={this.toggle}>
          <Row className="no-gutters">
            <Col xs="2">
              <Info className="stroke" />
            </Col>
            <Col xs="10">
              <FormattedMessage id="tutorial.openModal" />
            </Col>
          </Row>
        </span>
        <Modal
          className="tutorial"
          isOpen={this.state.modal}
          onClosed={this.handleClose}
          toggle={this.toggle}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            <FormattedMessage id="tutorial.title" />
          </ModalHeader>
          <ModalBody>
            <TutorialCarousel />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

TutorialModal.propTypes = {
  user: PropTypes.object.isRequired,
  deactivateUserTutorial: PropTypes.func
};

function mapStateToProps(state) {
  return {
    user: state.user.data
  };
}

export default connect(
  mapStateToProps,
  { deactivateUserTutorial: apiService.deactivateUserTutorial }
)(TutorialModal);
