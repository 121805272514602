import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Col, Row } from 'reactstrap';
import { StatusInfo } from 'grommet-icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import DetailTabs from './components/DetailTabs';
import DetailAccordion from './components/DetailAccordion';
import Gene from '../../components/Gene';

export class Recommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      geneInformation: '',
      data: {}
    };
  }

  componentDidMount() {
    const { searchResults, geneName } = this.props;
    const data = this.getSelectedResults(searchResults, geneName);
    if (data) {
      this.setState({
        data: data
      });
    } else {
      window.location.href = '/search';
    }
  }

  getSelectedResults = (searchResults, geneName) =>
    searchResults.find(sr => sr.gene_name === geneName);

  render() {
    const { data } = this.state;
    const { geneName } = this.props;

    const FieldContent = props =>
      props.content || props.children ? (
        <>
          {props.children}
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </>
      ) : (
        <FormattedMessage id={'search.no_info'} />
      );

    const fields = [
      {
        key: 'recommendation',
        content: (
          <FieldContent content={data.guideline}>
            {data.recommendation_status === 'action_required' && (
              <Alert color="light" className="mb-4">
                <Row>
                  <Col
                    xs="1"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <StatusInfo className="stroke" />
                  </Col>
                  <Col>
                    <FormattedMessage id="recommendation.notice" />
                  </Col>
                </Row>
              </Alert>
            )}
          </FieldContent>
        )
      },
      {
        key: 'gene_info',
        content: (
          <FieldContent>
            <Gene id={geneName} />
          </FieldContent>
        )
      },
      {
        key: 'background_info',
        content: <FieldContent content={data.background_info} />
      },
      {
        key: 'references',
        content: <FieldContent content={data.references} />
      }
    ];

    return (
      <div className="recommendation">
        <DetailTabs fields={fields} />
        <DetailAccordion fields={fields} />
      </div>
    );
  }
}

Recommendation.propTypes = {
  searchResults: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  searchItem: PropTypes.object,
  geneName: PropTypes.string
};

const mapStateToProps = state => {
  return {
    searchResults: state.search.searchResults,
    searchItem: state.search.searchItem
  };
};

export default injectIntl(connect(mapStateToProps)(Recommendation));
