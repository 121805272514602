import React from 'react';
import Overlay from '../../../../components/Overlay';

class Header extends React.Component {
  render() {
    return (
      <>
        <Overlay messageId="settings.loading" restrictToPath="/settings" />
      </>
    );
  }
}

export default Header;
