import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import { createLogger } from 'redux-logger';
import throttle from 'lodash/throttle';
import {
  authMiddleware,
  refreshTokenMiddleware,
  userUpdate,
  isRefreshing,
  isAuthenticated
} from '@brightsolutionsgmbh/client-core';
import { loadState, saveState } from './localStorage';
import rootReducer from './reducers';

const configureStore = () => {
  // redux debugging extension for chrome
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const presistedState = loadState();

  const middlewares = [
    isRefreshing,
    refreshTokenMiddleware, // retireve a new access token if needed and resend api call
    authMiddleware, // authenticate api requests by adding token to header
    apiMiddleware, // catch RSAA api calls and handle them
    isAuthenticated, // check if success reponse is empty due to not-authenticated response
    userUpdate, // refresh token after changes to user reducer
    thunkMiddleware
  ];

  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(
      createLogger({
        collapsed: true
      })
    );
  }

  const store = createStore(
    rootReducer,
    presistedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  // update oauth tokens on state change
  // allow state update only at max every second using throttle
  store.subscribe(
    throttle(() => {
      const { navbar, ...rest } = store.getState();
      saveState(rest);
    }),
    1000
  );

  return store;
};

export default configureStore;
