import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, SchemaForm } from '@brightsolutionsgmbh/biologis-react-components';
import { downloadFileFromBlob } from '@brightsolutionsgmbh/client-core';
import { StatusCritical, DocumentPdf } from 'grommet-icons';
import { Button, Spinner } from 'reactstrap';
import { sendNotification } from '../../../../utils/notifications';

const printJobStatusList = {
  ready: {
    key: 'ready',
    messageId: 'printJobStatusReady',
    icon: <DocumentPdf className="primary" />
  },
  error: {
    key: 'error',
    messageId: 'printJobStatusError',
    icon: <StatusCritical className="red" />
  },
  expired: {
    key: 'expired',
    messageId: 'printJobStatusExpired',
    icon: <StatusCritical className="red" />
  },
  pending: {
    key: 'pending',
    messageId: 'printJobStatusPending',
    icon: <Spinner size="sm" color="primary" />
  }
};

export class PrintJob extends Component {
  intervalDuration = 4000;

  constructor(props) {
    super(props);
    this.reportIsGeneratingModal = React.createRef();

    this.state = {
      printJobStatus: undefined
    };
  }

  isReady = () => this.props.printJob.status === printJobStatusList.ready.key;

  isError = () => this.props.printJob.status === printJobStatusList.error.key;

  isPending = () =>
    this.props.printJob.status === printJobStatusList.pending.key;

  componentDidMount() {
    const { getPrintJob, printJob } = this.props;

    if (printJob.id) {
      getPrintJob(printJob.id).then(() => {
        if (this.isError()) {
          // remove error status message on first load
          this.setState({
            printJobStatus: undefined
          });
        } else {
          this.setState(
            {
              printJobStatus: printJob.status
            },
            () => {
              // restart polling if status in store is pending
              if (
                this.state.printJobStatus === printJobStatusList.pending.key
              ) {
                this.pollPrintJob();
              }
            }
          );
        }
      });
    } else if (this.isError()) {
      // remove error status message on first load
      this.setState({
        printJobStatus: undefined
      });
    }
  }

  componentWillUnmount() {
    if (this.printJobPolling) {
      clearInterval(this.printJobPolling);
    }
  }

  pollPrintJob() {
    const { getPrintJob } = this.props;

    // set status message to pending
    this.setState(
      {
        printJobStatus: printJobStatusList.pending.key
      },
      () => {
        this.printJobPolling = setInterval(() => {
          const { printJob } = this.props;
          getPrintJob(printJob.id)
            .then(() => {
              if (this.isPending()) {
                // continue polling
                return Promise.resolve;
              } else if (this.isReady()) {
                this.setState(
                  { printJobStatus: printJobStatusList.ready.key },
                  () => {
                    // stop polling
                    clearInterval(this.printJobPolling);
                    // notify user about successfull report generation
                    sendNotification(
                      this.props.intl.formatMessage({
                        id: 'navbar.report'
                      }),
                      this.props.intl.formatMessage({
                        id: 'report.printJobStatusReady'
                      })
                    );
                  }
                );
              } else {
                this.setState(
                  { printJobStatus: printJobStatusList.error.key },
                  () => {
                    // stop polling
                    clearInterval(this.printJobPolling);
                    // notify user about error while creating report
                    sendNotification(
                      this.props.intl.formatMessage({
                        id: 'navbar.report'
                      }),
                      this.props.intl.formatMessage({
                        id: 'report.printJobStatusError'
                      })
                    );
                  }
                );
              }
            })
            .catch(() => clearInterval(this.printJobPolling));
        }, this.intervalDuration);
      }
    );
  }

  downloadFile = uri => {
    const { downloadFile } = this.props;
    const splittedUri = uri.split('/');
    const filename = splittedUri[splittedUri.length - 1];

    downloadFile(uri).then(response => {
      if (response.payload.status === 200) {
        response.payload
          .blob()
          .then(blob => downloadFileFromBlob(blob, filename));
      } else {
        // if response is not 200, then the report expired (error 404)
        this.setState({
          printJobStatus: printJobStatusList.expired.key
        });
      }
    });
  };

  submitForm = (values, actions, toggle) => {
    // set status to pending
    this.setState(
      {
        printJobStatus: printJobStatusList.pending.key
      },
      // then start print job
      () => {
        const { createPrintJob, lang, clinicalReport } = this.props;
        const filledContentVariables = [];
        const parsedContentVariables = JSON.parse(
          clinicalReport.attributes.template_content_variables
        );

        Object.keys(parsedContentVariables).forEach(key => {
          Object.keys(parsedContentVariables[key].content_variables).forEach(
            k => {
              const contentVariable =
                parsedContentVariables[key].content_variables[k];

              const variable = {};
              variable.key = contentVariable.name;
              variable.value = values[contentVariable.name];
              variable.type = contentVariable.type;
              filledContentVariables.push(variable);
            }
          );
        });

        createPrintJob({
          id: clinicalReport.attributes.drupal_internal__nid,
          targetType: 'node',
          printType: 'pdf',
          contentVariables: filledContentVariables,
          langCode: lang
        })
          .then(() => {
            actions.setSubmitting(false);
            // start polling print job
            this.pollPrintJob();
            // handle modals
            toggle();
            this.reportIsGeneratingModal.current.onModalOpen();
          })
          .catch(e => {
            console.log(e);

            this.setState({
              printJobStatus: printJobStatusList.error.key
            });
          });
      }
    );
  };

  render() {
    const { clinicalReport, intl, printJob } = this.props;
    const { printJobStatus } = this.state;
    let formFields = '';

    if (
      ((clinicalReport.attributes || {}).template_content_variables || '')
        .length > 0
    ) {
      formFields = JSON.parse(
        clinicalReport.attributes.template_content_variables
      );
    }

    return (
      <div className="download-section">
        <Modal
          buttonColor="primary"
          buttonLabel={intl.formatMessage({
            id: 'report.personalizeButton'
          })}
          disableButton={printJobStatus === printJobStatusList.pending.key}
          withFooter={false}
          render={toggle => (
            <>
              {formFields ? (
                <SchemaForm
                  submit={(values, actions) =>
                    this.submitForm(values, actions, toggle)
                  }
                  formFields={formFields}
                  submitButtonLabel={intl.formatMessage({
                    id: 'report.sendButton'
                  })}
                />
              ) : (
                <FormattedMessage id="report.noPersonalizationFields" />
              )}
            </>
          )}
        />

        <div className="pt-3">
          {printJobStatus && (
            <>
              {printJobStatusList[printJobStatus].icon}{' '}
              <FormattedMessage
                id={`report.${printJobStatusList[printJobStatus].messageId}`}
              />
            </>
          )}
          {printJobStatus === printJobStatusList.ready.key && (
            <Button
              color="link"
              className="pdf-link"
              onClick={() => this.downloadFile(printJob.data.files[0].link)}
            >
              <FormattedMessage id="report.downloadButton" />
            </Button>
          )}
        </div>

        <Modal
          ref={this.reportIsGeneratingModal}
          hideButton
          withFooter={false}
          render={toggle => (
            <div>
              <p>
                <FormattedMessage id="report.reportIsGenerating" />
              </p>
              <Button color="primary" onClick={() => toggle()}>
                Ok
              </Button>
            </div>
          )}
        />
      </div>
    );
  }
}

PrintJob.propTypes = {
  createPrintJob: PropTypes.func.isRequired,
  getPrintJob: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  clinicalReport: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  printJob: PropTypes.object,
  accessToken: PropTypes.string
};

export default injectIntl(PrintJob);
