import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {Outlet } from 'react-router-dom';
import Navbar from './components/Navbar';
import RightSidebar from '../../components/RightSidebar';

class Main extends Component {
  render() {
    return (
        <Container fluid className="app">
          <Row>
            <Col lg="2" xs="12" className="sidebar">
              <Navbar />
            </Col>
            <Col lg={{ size: 8 }} className="main">
              <main className="content">
                  <Outlet />
              </main>
            </Col>
            <Col lg="2" xs="12" className="sidebar">
              {/* Add content for the right sidebar here */}
              <RightSidebar />
            </Col>
          </Row>
        </Container>
    );
  }
}

export default Main;
