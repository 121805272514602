import React, { Component } from 'react';
import {
  Col,
  Row,
  Container,
  Form,
  Input,
  FormGroup,
  Label,
  UncontrolledAlert
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Footer from '../../components/Footer';
import LanguageSwitcher from '../../components/LangSwitcher';
import RightSidebar from '../../components/RightSidebar';
import { apiService, stateService } from '../../services';
import { SubmitButton } from '@brightsolutionsgmbh/biologis-react-components';

export class PasswordResetForm extends Component {
  constructor(props) {
    super(props);

    const { uid, timestamp, hash } = this.props.match.params;

    this.state = {
      newPassword: '',
      newPasswordConfirmation: '',
      isPasswordConfirmed: false,
      isFormSubmitted: false,
      uid: parseInt(uid, 10), // cast to integer as required by jsonrpc
      hash,
      timestamp
    };
  }

  handleChange = ev => {
    this.setState({
      [ev.target.id]: ev.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { uid, hash, timestamp } = this.state;

    this.setState(
      {
        isFormSubmitted: false
      },
      () => {
        if (
          this.state.newPassword !== '' &&
          this.state.newPassword === this.state.newPasswordConfirmation
        ) {
          this.setState({
            isFormSubmitted: true,
            isPasswordConfirmed: true
          });
          this.props.setNewUserPassword({
            pass: this.state.newPassword,
            uid,
            hash,
            timestamp
          });
        } else {
          this.setState({
            isFormSubmitted: true,
            isPasswordConfirmed: false
          });
        }
      }
    );
  };

  render() {
    const { error, isFetching } = this.props;
    const {
      newPassword,
      newPasswordConfirmation,
      isPasswordConfirmed,
      isFormSubmitted
    } = this.state;

    return (
      <Container>
        <Row>
          <Col
            lg="10"
            className="d-flex flex-column justify-content-between align-items-center"
          >
            <LanguageSwitcher
              handleLangChange={lang => this.props.setLangLocally(lang)}
            />
            <div className="reset-password-form">
              <h3>
                <FormattedMessage id="passwordResetForm.header" />
              </h3>

              {!isFetching &&
              isFormSubmitted &&
              isPasswordConfirmed &&
              !error ? (
                <p>
                  <FormattedMessage id="passwordResetForm.success" />
                  <br />
                  <Link to="/login" className="btn btn-link my-4">
                    <FormattedMessage id="passwordResetForm.redirect" />
                  </Link>
                </p>
              ) : (
                <>
                  <Form onSubmit={this.handleSubmit}>
                    <Label for="newPassword">
                      <FormattedMessage id="passwordResetForm.newPassword" />
                    </Label>
                    <FormGroup>
                      <Input
                        id="newPassword"
                        type="password"
                        required
                        onChange={this.handleChange}
                        value={newPassword}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="newPasswordConfirmation">
                        <FormattedMessage id="passwordResetForm.newPasswordConfirmation" />
                      </Label>
                      <Input
                        id="newPasswordConfirmation"
                        type="password"
                        required
                        onChange={this.handleChange}
                        value={newPasswordConfirmation}
                      />
                    </FormGroup>
                    {!isFetching && (
                      <>
                        {isFormSubmitted && !isPasswordConfirmed && (
                          <UncontrolledAlert color="danger">
                            <FormattedMessage id="passwordResetForm.confirmationError" />
                          </UncontrolledAlert>
                        )}
                        {isFormSubmitted && isPasswordConfirmed && error && (
                          <UncontrolledAlert color="danger">
                            <FormattedMessage id="passwordResetForm.error" />
                          </UncontrolledAlert>
                        )}
                      </>
                    )}
                    <Row>
                      <Col>
                        <SubmitButton isLoading={isFetching} size="lg">
                          <FormattedMessage id="passwordResetForm.submitButton" />
                        </SubmitButton>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </div>
            <Footer />
          </Col>
          <RightSidebar />
        </Row>
      </Container>
    );
  }
}

PasswordResetForm.propTypes = {
  setNewUserPassword: PropTypes.func,
  setLangLocally: PropTypes.func,
  error: PropTypes.bool,
  isFetching: PropTypes.bool,
  match: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    error: state.user.error,
    isFetching: state.user.isFetching
  };
};

export default connect(
  mapStateToProps,
  {
    setNewUserPassword: apiService.setNewUserPassword,
    setLangLocally: stateService.setLangLocally
  }
)(PasswordResetForm);
