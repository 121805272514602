import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <nav>
          <ul className="d-flex justify-content-center">
            <li className="d-block px-2">
              <Link to="/imprint">
                <FormattedMessage id="footer.imprint" />
              </Link>
            </li>
            <li className="d-block px-2">
              <Link to="/terms_and_conditions">
                <FormattedMessage id="footer.terms" />
              </Link>
            </li>
            <li className="d-block px-2">
              <Link to="/privacy_policy">
                <FormattedMessage id="footer.policy" />
              </Link>
            </li>
          </ul>
        </nav>
      </footer>
    );
  }
}

export default Footer;
